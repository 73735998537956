/**
 * Validator.js - provides class Validator with a lot of helper methods
 */

export default class Validator {

	static is( inputParameter, className ) {
		if ( !Validator.isString( className ) || !Validator.isObject( inputParameter ) ) {
			return false;
		}
		return Object.getPrototypeOf( inputParameter ).constructor.name === className;
	}

	static mightBe( inputParameter, className ) {
		if ( !Validator.isString( className ) || !Validator.isObject( inputParameter ) ) {
			return false;
		}
		return Object.getPrototypeOf( inputParameter ).constructor.name.toLowerCase().indexOf( className.toLowerCase() ) >= 0;
	}

	static couldBe( inputParameter, className ) {
		if ( !Validator.isString( className ) || !Validator.isObject( inputParameter ) ) {
			return false;
		}
		return Object.getPrototypeOf( inputParameter ).constructor.name.indexOf( className ) >= 0;
	}

	static getClassName( inputParameter ) {
		if ( !Validator.isObject( inputParameter ) ) return void 0;
		return Object.getPrototypeOf( inputParameter ).constructor.name;
	}

	static isObject( inputParameter ) {
		return !!inputParameter && typeof inputParameter === "object";
	}

	/**
	 * checks whether the specified object is a non empty string
	 * @param {String} inputParameter the object to be checked
	 * @returns {Boolean} true if the specified object is a non empty string; false otherwise
	 */
	static isString( inputParameter ) {
		return typeof inputParameter === "string" && inputParameter.length > 0;
	}

	/**
	 * always returns a valid string object
	 * @param {String} str the source string
	 * @returns the source string if it is a valid string; an empty string otherwise
	 */
	static ensureString(str) {
		return Validator.isString(str) ? str : '';
	}

	/**
	 * returns the effective string of two strings. If the first argument - the
	 * main string - a valid, non-empty string object, then this string is returned.
	 * Otherwise the alternative string is returned. If the alternative string is
	 * null or undefined then an empty string is returned
	 * @param {String} s1 main string
	 * @param {String} s2 alternative string
	 * @returns {String} the effective string
	 */
	static effectiveString(s1, s2) {
		return Validator.isString(s1) ? s1 : Validator.ensureString(s2);
	}

	/**
	 * checks whether the specified object is a non empty string
	 * @param {String} s the string to be checked
	 * @returns {Boolean} true if the specified object is a non empty string; false otherwise
	 * @deprecated
	 */
	static isStr(s) {
		console.warn('Deprecated method "Validator.isStr()" called! Use Validator.isString instead.');
		return Validator.isString(s);
	}

	static true( inputParameter ) {
		if ( inputParameter === true ) {
			return true;
		}
		return Validator.isString( inputParameter ) && Validator.isTrue( inputParameter.toLowerCase() );
	}

	/**
	 * checks whether a string explicitly specifies a boolean value of "true"
	 * @param {String} str string object
	 * @returns {Boolean} true if the string explicitly specifies a "true" value; false otheriwse
	 */
	static isTrue( str ) {
		return Validator.isString( str ) && ( str === 'true' || str === 'y' || str === 'TRUE' || str === 'Y' );
	}

	static false( inputParameter ) {
		if ( inputParameter === false ) {
			return true;
		}
		return Validator.isString( inputParameter ) && Validator.isFalse( inputParameter.toLowerCase() );
	}

	/**
	 * checks whether a string explicitly specifies a boolean value of "false"
	 * @param {String} str string object
	 * @returns {Boolean} true if the string explicitly specifies a "false" value; false otheriwse
	 */
	static isFalse( str ) {
		return Validator.isString( str ) && ( str === 'false' || str === 'n' || str === 'FALSE' || str === 'N' );
	}

	/**
	 * checks whether the specified object is a number
	 * @param {Number} inputParameter 
	 * @returns {Boolean} true if the specified object is a number; false otherwise
	 */
	static isNumber( inputParameter ) {
		return typeof inputParameter === "number";
	}

	/**
	 * checks whether the specified object is a valid string ir a valid number
	 * @param {String | Number} inputParameter 
	 * @returns {Boolean} true if the specified object is a valid string or a valid number; false otherwise
	 */
	static isStringOrNumber(o) {
		return Validator.isString(o) || Validator.isNumber(o);
	}

	static isValidNumber( inputParameter, canBeZero = true ) {
		if ( typeof inputParameter !== "number" ) return false;
		if ( inputParameter === 0 ) return !!canBeZero;
		return inputParameter / inputParameter === 1;
	}

	static isInteger( inputParameter ) {
		return Number.isInteger( inputParameter );
	}

	static isPositiveNumber( inputParameter, canBeZero = true ) {
		return Validator.isNumber( inputParameter ) && ( !canBeZero ? inputParameter > 0 : inputParameter >= 0 );
	}

	static isPositiveInteger( inputParameter, canBeZero = true ) {
		return Validator.isInteger( inputParameter ) && Validator.isPositiveNumber( inputParameter, canBeZero );
	}

	static isBoolean( inputParameter ) {
		return typeof inputParameter == "boolean";
	}

	static isFunction( inputParameter ) {
		return !!inputParameter && typeof inputParameter == "function";
	}

	static isArray( inputParameter, testLength = false ) {
		if ( !( inputParameter instanceof Array ) ) {
			return false;
		}
		if ( Validator.isBoolean( testLength ) ) {
			return !testLength ? true : inputParameter.length > 0;
		}
		if ( !Validator.isPositiveInteger( testLength, true ) ) {
			return true;
		}
		return inputParameter.length === testLength;
	}

	static arraysEqual( firstArray, secondArray ) {
		if ( !Validator.isArray( firstArray ) ||
			!Validator.isArray( secondArray ) ) return false;
		if ( firstArray.length != secondArray.length ) return false;
		return JSON.stringify( firstArray ) === JSON.stringify( secondArray );
	}

	static removeElementFromArray( array, element ) {
		if ( !Validator.isArray( array, true ) ) return false;
		const index = Validator.getIndexInArray( array, element );
		if ( !Validator.isPositiveInteger( index ) ) return false;
		if ( index >= array.length ) return false;
		array.splice( index, 1 );
		return true;
	}

	static getIndexInArray( array, element ) {
		if ( !Validator.isArray( array, true ) ) return void 0;
		let elementIndex = array.indexOf( element );
		if ( Validator.isPositiveInteger( elementIndex ) ) return elementIndex;
		for ( let index = 0; index < array.length; index++ ) {
			const arrayElement = array[ index ];
			if ( arrayElement !== element ) continue;
			elementIndex = index;
			break;
		}
		return Validator.isPositiveInteger( elementIndex ) ? elementIndex : void 0;
	}

	static deduplicateArray( array ) {
		if ( !Validator.isArray( array ) ) return [];
		if ( array.length < 2 ) return array;
		return [ ...new Set( array ) ];
	}

	static hasDataset( element ) {
		return element instanceof HTMLElement &&
			element.dataset instanceof DOMStringMap;
	}

	/**
	 * @deprecated
	 * @param {*} inputParameter 
	 * @returns 
	 */
	static deepClone( inputParameter ) {
		throw new Error(`Don't call Validator.deepClone!`);
	}

	/**
	 * compare two numbers
	 * @param {Number} n1 first number
	 * @param {Number} n2 second number
	 * @returns {Number} -1 if n1 < n2; 1 if n1 > n2; 0 if n1 === n2
	 */
	static numericCompare( n1, n2 ) {
		return ( n1 < n2 ) ? -1 : ( ( n1 > n2 ) ? 1 : 0 );
	}

	static sort( somethingIterable, ascending = true ) {
		if ( !Validator.isObject( somethingIterable ) ) {
			return somethingIterable;
		}
		let sortedSomething = Array.from( somethingIterable ).sort( ( member, nextMember ) => {
				const numericalMember = Number( member );
				const numericalNextMember = Number( nextMember );
				return Validator.numericCompare( numericalMember, numericalNextMember );
			} );
		if ( !ascending ) {
			sortedSomething.reverse();
		}
		return sortedSomething;
	}

	static isMap( inputParameter, testSize = false ) {
		return !( inputParameter instanceof Map ) ? false : (!testSize ? true : inputParameter.size > 0);
	}

	static isSet( inputParameter, testSize = false ) {
		return !( inputParameter instanceof Set ) ? false : (!testSize ? true : inputParameter.size > 0);
	}

	static isIterable( inputParameter ) {
		if ( !Validator.isObject( inputParameter ) && !Validator.isString( inputParameter ) ) {
			return false;
		}
		return Validator.isFunction( inputParameter[ Symbol.iterator ] );
	}

	static _getObjectFromHierarchy( parentObject, pathArray = [], validateEachLevel = false ) {
		if ( !Validator.isObject( parentObject ) ) {
			return void 0;
		} 
		let finalObject = parentObject;
		for ( let childLevelName of pathArray ) {
			finalObject = finalObject[ childLevelName ];
			if ( !!validateEachLevel && !Validator.isObject( finalObject ) ) {
				return void 0;
			}
		}
		return finalObject;
	}

	static _isObjectHierarchy( parentObject, levelsArray = [] ) {
		if ( !Validator.isObject( parentObject ) ) return false;
		let currentlyValidating = parentObject;
		for ( let childLevelName of levelsArray ) {
			currentlyValidating = currentlyValidating[ childLevelName ];
			if ( !Validator.isObject( currentlyValidating ) ) return false;
		}
		return true;
	}

	static isObjectPath( parentObject, pathString = "" ) {
		if ( !Validator.isFunction( pathString.split ) ) return false;
		let pathArray = pathString.split( "." );
		if ( pathArray.length > 0 ) pathArray.shift();
		return Validator._isObjectHierarchy( parentObject, pathArray );
	}

	static isFunctionPath( parentObject, pathString = "" ) {
		if ( !Validator.isString( pathString ) )
			return Validator.isFunction( pathString ) ||
				Validator.isFunction( parentObject );
		if ( pathString.indexOf( "." ) < 0 )
			return Validator.isObject( parentObject ) &&
				Validator.isFunction( parentObject[ pathString ] );
		let pathArray = pathString.split( "." );
		if ( pathArray.length < 1 ) return Validator.isFunction( parentObject );
		if ( pathArray.length == 1 ) return Validator.isFunction( parentObject ) ||
			Validator.isObject( parentObject ) &&
			Validator.isFunction( parentObject[ pathString ] );
		pathArray.shift();
		const functionName = pathArray[ pathArray.length - 1 ];
		pathArray.splice( -1 );
		if ( !Validator._isObjectHierarchy( parentObject, pathArray ) ) return false;
		const finalObject = Validator._getObjectFromHierarchy( parentObject, pathArray );
		return Validator.isObject( finalObject ) &&
			Validator.isFunction( finalObject[ functionName ] );
	}

	static _getFirstMatchArr( source, regExp ) {
		const matches = Validator.getMatches( source, regExp );
		if ( !Validator.isArray( matches, true ) ) return void 0;
		const firstMatch = matches[ 0 ];
		if ( !Validator.isArray( firstMatch, true ) ) return void 0;
		return firstMatch;
	}

	static firstMatchValue( source, regExp ) {
		const firstMatchArr = Validator._getFirstMatchArr( source, regExp );
		if ( !Validator.isArray( firstMatchArr, true ) ) return void 0;
		const value = firstMatchArr[ 0 ];
		return Validator.isString( value ) ? value : void 0;
	}

	static firstMatchIndex( source, regExp ) {
		const firstMatch = Validator._getFirstMatchArr( source, regExp );
		if ( !Validator.isArray( firstMatch, true ) ) return -1;
		const index = firstMatch.index;
		return Validator.isPositiveInteger( index ) ? index : -1;
	}

	static getMatches( source, regExp ) {
		if ( !Validator.isString( source ) ) return [];
		if ( Validator.isString( regExp ) ) regExp = new RegExp( regExp, "g" );
		if ( !( regExp instanceof RegExp ) ||
			!Validator.isFunction( source.matchAll ) ) return [];
		const matchAllResult = source.matchAll( regExp );
		if ( !Validator.isIterable( matchAllResult ) ) return [];
		const matches = [ ...matchAllResult ];
		return Validator.isArray( matches, true ) ? matches : [];
	}

	static getMatchesCount( source, regExp ) {
		const matches = Validator.getMatches( source, regExp );
		return Validator.isArray( matches, true ) ? matches.length : 0;
	}

	static mirrorFromInstance( hostObject, instanceToMirror ) {
		if ( !Validator.isObject( hostObject ) ||
			!Validator.isObject( instanceToMirror ) ) return false;
		const functionsToMirror = Object.getOwnPropertyNames(
				Object.getPrototypeOf( instanceToMirror ) )
			.filter( propertyName =>
				Validator.isFunction( instanceToMirror[ propertyName ] ) );
		for ( let functionName of functionsToMirror )
			hostObject[ functionName ] = instanceToMirror[ functionName ];
		return true;
	}

	static mirrorFromClass( hostObject, classToMirror ) {
		if ( !Validator.isObject( hostObject ) ||
			!Validator.isFunction( classToMirror ) ) return false;
		const functionsToMirror = Object.getOwnPropertyNames( classToMirror )
			.filter( propertyName =>
				Validator.isFunction( classToMirror[ propertyName ] ) );
		for ( let functionName of functionsToMirror )
			hostObject[ functionName ] = classToMirror[ functionName ];
		return true;
	}

	static attachValidatorTo( hostObject ) {
		return Validator.mirrorFromClass( hostObject, Validator );
	}

	static unmodifiableGetter( { hostObject, getterName, getCallback, configurable = false } ) {
		if ( !Validator.isObject( hostObject ) ) return false;
		if ( !Validator.isString( getterName ) ) return false;
		if ( !Validator.isFunction( getCallback ) ) return false;
		Validator._addUnmodifiableGetter( {
			hostObject: hostObject,
			getterName: getterName,
			getCallback: getCallback,
			configurable: configurable
		} );
	}

	static _addUnmodifiableGetter( { hostObject, getterName, getCallback, configurable = false } ) {
		Object.defineProperty( hostObject, getterName, {
			get: () => {
				return getCallback();
			},
			set: ( newPropertyValue ) => {
				Warner._trace( `The value of the property ${ getterName } can not be` +
					` modified.` );
			},
			configurable: !!configurable
		} );
	}

	static getDescriptor( parentObject, propertyName ) {
		if ( !Validator.isString( propertyName ) ) return void 0;
		if ( !Validator.isObject( parentObject ) ) return void 0;
		return Object.getOwnPropertyDescriptor( parentObject, propertyName );
	}

	static hasSetter( parentObject, propertyName ) {
		let setter = Validator.getSetter( parentObject, propertyName );
		return Validator.isFunction( setter );
	}

	static getSetter( parentObject, propertyName ) {
		let descriptor = Validator.getDescriptor( parentObject, propertyName );
		if ( !Validator.isObject( descriptor ) ) return void 0;
		return Validator.isFunction( descriptor.set ) ? descriptor.set : void 0;
	}

	static hasGetter( parentObject, propertyName ) {
		let getter = Validator.getGetter( parentObject, propertyName );
		return Validator.isFunction( getter );
	}

	static getGetter( parentObject, propertyName ) {
		let descriptor = Validator.getDescriptor( parentObject, propertyName );
		if ( !Validator.isObject( descriptor ) ) return void 0;
		return Validator.isFunction( descriptor.get ) ? descriptor.get : void 0;
	}

	static applyFunctionFromPrototype( {
		prototype,
		functionName,
		scope,
		functionArguments
	} ) {
		if ( !Validator.isString( functionName ) ) return void 0;
		const functionToApply = Object.getOwnPropertyDescriptor( prototype,
			functionName ).value;
		if ( !Validator.isFunction( functionToApply ) ) return void 0;
		if ( !Validator.isArray( functionArguments ) ) functionArguments = [ functionArguments ];
		return functionToApply.apply( scope, functionArguments );
	}

	static callGetterFromPrototype( {
		prototype,
		getterName,
		scope
	} ) {
		if ( !Validator.isString( getterName ) ) return void 0;
		const getterFunction = Object.getOwnPropertyDescriptor( prototype,
			getterName ).get;
		if ( !Validator.isFunction( getterFunction ) ) return void 0;
		return getterFunction.call( scope );
	}

	static generateRandomString( prefix = "", suffix = "", length = void 0 ) {
		let now = Date.now();
		let randomString = ( now * Math.random() ).toString( 36 ).replace( /\./g, now );
		if ( Validator.isPositiveInteger( length ) ) {
			randomString = randomString.length >= length ? randomString.substring( 0, length - 1 ) : randomString + Math.pow( 10, length - randomString.length - 1 );
		}
		if ( Validator.isString( prefix ) ) {
			randomString = prefix + randomString;
		} 
		if ( Validator.isString( suffix ) ) {
			randomString = randomString + suffix;
		}
		return randomString;
	}

	static getGreatestCommonDivisor( numbers ) {
		if ( !Validator.isArray( numbers, true ) ) return void 0;
		numbers = Array.from( new Set( numbers ) ).filter( possibleNumber =>
			Validator.isValidNumber( possibleNumber ) );
		if ( !Validator.isArray( numbers, true ) ) return void 0;
		let greatestDenominator = numbers[ 0 ];
		for ( let number of numbers ) greatestDenominator =
			Validator.getGreatestDenominator( greatestDenominator, number );
		return greatestDenominator;
	}

	static getGreatestDenominator( firstNumber, secondNumber ) {
		firstNumber = Number( firstNumber );
		secondNumber = Number( secondNumber );
		if ( !Validator.isValidNumber( firstNumber ) ||
			!Validator.isValidNumber( secondNumber ) ) return void 0;
		if ( !Validator.isInteger( firstNumber ) )
			firstNumber = Math.round( firstNumber );
		if ( firstNumber < 0 ) firstNumber = 0;
		if ( !Validator.isInteger( secondNumber ) )
			secondNumber = Math.round( secondNumber );
		if ( secondNumber < 0 ) secondNumber = 0;
		return Validator._getGreatestDenominator( firstNumber, secondNumber );
	}

	static _getGreatestDenominator( firstNumber, secondNumber ) {
		return !secondNumber ? firstNumber :
			Validator._getGreatestDenominator( secondNumber, firstNumber % secondNumber );
	}

	static setupInfiniteSetter( {
		value,
		instance,
		propertyName,
		callback,
		stopSetterBasedOnCallbackResult = false
	} ) {
		if ( !Validator.isFunction( callback ) ||
			!Validator.isString( propertyName ) ||
			!Validator.isObject( instance ) ) {
			return false;
		}
		const callBackResult = callback( value );
		if ( !!stopSetterBasedOnCallbackResult && !callBackResult ) {
			return;
		}
		Object.defineProperty( instance, propertyName, {
			set: ( newValue ) => {
				const setupSuccessful = Validator.setupInfiniteSetter( {
					value: newValue,
					instance: instance,
					propertyName: propertyName,
					callback: callback,
					stopSetterBasedOnCallbackResult: !!stopSetterBasedOnCallbackResult
				} )
			},
			get: () => { return value; },
			configurable: true
		} );
		return true;
	}

	/**
	 * checks whether a list of attribute values matches a reference attribute value
	 * @param {String} reference reference attribute
	 * @param {Number} deviation allowed deviation (use values frm 0.01 [1%] to 1.00 [100%])
	 * @param {Array<String>} values values to be checked
	 * @returns {Boolean} true if all given attribute values match the reference attribute value; false otherwise
	 */
	static checkAttrRange( reference, deviation, values ) {
		if ( !this.isString( reference ) || typeof deviation !== 'number' || !values.length ) {
			return false;
		}
		const ref_val = Number.parseFloat( reference );
		if ( Number.isNaN( ref_val ) ) {
			return false;
		}
		const range = Math.max( ref_val * deviation, 0.01 );
		return values.every( a => {
			const v = Number.parseFloat( a );
			return !Number.isNaN( v ) && ( Math.abs( ref_val - v ) <= range );
		} );
	}
}

console.debug( 'utils/Validator.js loaded.' );
