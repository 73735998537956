import AttachmentObject from '../../../../utils/AttachmentObject';
import Checkbox from './Checkbox';
import DropdownInputField from './DropdownInputField';
import EventListenerManager from '../../../../utils/EventListenerManager';
import InputField from './InputField';
import Textarea from './Textarea';
import Validator from '../../../../utils/Validator';
import DomEventHelper from '../../../../utils/DomEventHelper';
import Warner from '../../../../utils/Warner';
import XtwUtils from '../../util/XtwUtils';
import XtwCol from '../../parts/XtwCol';
import XtwModel from '../../model/XtwModel';
import XtwBody from '../../XtwBody';
import XRowItem from '../../parts/XRowItem';
import ThemeMgr from '../../../../gui/ThemeMgr';
import XCellItem from '../../parts/XCellItem';
import MRowItem from '../../model/MRowItem';
import EditableElement from './EditableElement';

const DEFAULT_PEN_SIZE = 12;

export default class XtwCellEditingExtension extends AttachmentObject {

	constructor( parentObject ) {
		super( parentObject );
		// any getters and setters declared in the constructor after calling this
		// function will not be mirrored/assigned
		this.assignGettersAndSettersTo( parentObject );
		// we do not want this constructor to be hanging on the host object,
		// because the host object has his own prototype and this is supposed to
		// be a one-time assignment
		parentObject.constructor = void 0;
		delete parentObject.constructor;
	}

	registerEditing() {
		if ( !( this.inputField instanceof InputField ) ) {
			return false;
		}
		return this.inputField.register();
	}

	/**
	 * @returns {XtwBody} the table body
	 */
	get xtwBody() {
		const row = this.row;
		if ( row instanceof XRowItem ) {
			return row.tblBody;
		} else {
			return null;
		}
	}

	/**
	 * @return {XtwModel} the data model
	 */
	get model() {
		const xtwBody = this.xtwBody;
		if ( xtwBody instanceof XtwBody ) {
			return xtwBody.model;
		} else {
			return null;
		}
	}

	get flatModel() {
		const model = this.model;
		if ( model instanceof XtwModel ) {
			return model.flatModel;
		} else {
			return [];
		}
	}

	get _flatIndex() {
		const row = this.row;
		if ( row instanceof XRowItem ) {
			return row.flatIndex;
		} else {
			return -1;
		}
	}

	/**
	 * @deprecated
	 */
	get selectionManager() {
		console.warn('Deprecated property "selectionManager" accessed!', Warner.getStack());
		return null;
	}

	get isBlob() {
		const column = this.column;
		return column instanceof XtwCol ? column.isBlob : false;
	}

	get isFocused() {
		const model = this.model;
		if ( model ) {
			return (this.idc > 0) && (model.focusedColumn === this.idc) && model.isRowFocused(this.row);
		} else {
			return false;
		}
	}

	get canHaveDropdown() {
		const column = this.column;
		if ( !( column instanceof XtwCol ) ) {
			return false;
		}
		return !!column.dropdown;
	}

	get type() {
		const column = this.column;
		if ( !(column instanceof XtwCol) ) {
			return -1;
		}
		return column.type;
	}

	get isTextDataType() {
		const column = this.column;
		if ( !( column instanceof XtwCol ) ) {
			return false;
		}
		return column.type === 0;
	}

	get isNumericDataType() {
		const column = this.column;
		if ( !( column instanceof XtwCol ) ) {
			return false;
		}
		return column.type === 1;
	}

	get isDateTimeDataType() {
		const column = this.column;
		if ( !( column instanceof XtwCol ) ) {
			return false;
		}
		return column.type === 2;
	}

	get isLogicDataType() {
		const column = this.column;
		if ( !( column instanceof XtwCol ) ) {
			return false;
		}
		return column.type === 3;
	}

	get isBlobDataType() {
		const column = this.column;
		if ( !( column instanceof XtwCol ) ) {
			return false;
		}
		return column.type === 4;
	}

	addEditingListeners() {
		const cellContainerListenerSuccessfullyAdded =
			EventListenerManager.addListener( {
				instance: this,
				eventName: "click",
				functionName: "onCellContainerClick",
				callBackPrefix: "CellContainer",
				element: this.element,
				useCapture: false
			} );
		if ( cellContainerListenerSuccessfullyAdded ) {
			// this is supposed to be a one-time call
			delete this.addEditingListeners;
		}
		return cellContainerListenerSuccessfullyAdded;
	}

	addKeyListeners() {
		if ( !this.isRendered ) {
			return false;
		}
		this.element.tabIndex = 1;
		EventListenerManager.addListener( {
			instance: this,
			eventName: 'keydown',
			functionName: 'onCellContainerKeyDown',
			callBackPrefix: 'CellContainer',
			element: this.element,
			useCapture: false
		});
		const keyupListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "keyup",
			functionName: "onCellContainerKeyUp",
			callBackPrefix: "CellContainer",
			element: this.element,
			useCapture: false
		} );

		if ( keyupListenerSuccessfullyAdded ) {
			// this is supposed to be a one-time call
			delete this.addKeyListeners;
		}
		return keyupListenerSuccessfullyAdded;
	}

	addDoubleClickListener() {
		const cellContainerListenerSuccessfullyAdded =
			EventListenerManager.addListener( {
				instance: this,
				eventName: "dblclick",
				functionName: "onCellContainerDoubleClick",
				callBackPrefix: "CellContainer",
				element: this.element,
				useCapture: false
			} );
		if ( cellContainerListenerSuccessfullyAdded ) {
			// this is supposed to be a one-time call
			delete this.addDoubleClickListener;
		}
		return cellContainerListenerSuccessfullyAdded;
	}

	/**
	 * focusing a cell automatically focuses a row and selects it in case the
	 * selection mode allows it, whilst taking the shift & command keys into
	 * account (the "selected" status of other row/model items is taken into
	 * account and may be influenced)
	 * @param {Event} domEvent the DOM event
	 */
	focusCell( domEvent ) {
		if ( !this.isRendered || !this.row ) {
			return false;
		}
		// create custom event
		const domEventIsValid = domEvent instanceof MouseEvent || domEvent instanceof KeyboardEvent;
		const controlPressed = domEventIsValid && XtwUtils.isCommandKeyPressed( domEvent );
		const shiftPressed = domEventIsValid && domEvent.shiftKey;
		const detail = {
			colId: this.idc,
			rowId: this.row.rowId,
			controlPressed: controlPressed,
			shiftPressed: shiftPressed,
			domEvent: domEventIsValid ? domEvent : null
		};
		const event = new CustomEvent('xcellclicked', { bubbles: true, detail: detail });
		this.row.getDomElement().dispatchEvent(event);
	}

	get editingPen() {
		const penSpan = window.document.createElement( "span" );
		penSpan.className = 'xtweditpen';
		const prop = ThemeMgr.getInstance().getProp('tblPenSize') || {};
		penSpan.style.fontSize = '' + (prop.type === 'number' ? prop.value : DEFAULT_PEN_SIZE) + 'px';
		const pen = window.document.createElement( "i" );
		pen.classList.add( "fal", "fa-pencil" );
		penSpan.appendChild( pen );
		return penSpan;
	}

	get focusedEditingPen() {
		// use the same as above...
		return this.editingPen;
	}

	_addPen( focused = false ) {
		if ( !this.isRendered ) {
			return false;
		}
		this.removeSelectionArrow();
		const element = this.element;
		element.style.alignItems = "center";
		const editingPen = !!focused ? this.focusedEditingPen : this.editingPen;
		element.appendChild( editingPen );
		return true;
	}

	addEditingPen() {
		return this._addPen( false );
	}

	removeEditingPen() {
		return this.removeSelectionArrow();
	}

	addFocusedEditingPen() {
		return this._addPen( true );
	}

	cleanLastEditedCell() {
		const tableBody = this.xtwBody;
		if ( !Validator.isObject( tableBody ) ||
			!Validator.isFunction( tableBody.cleanLastEditedCell ) ) {
			return false;
		}
		return tableBody.cleanLastEditedCell();
	}

	cleanFromEditables() {
		const tableBody = this.xtwBody;
		if ( !Validator.isObject( tableBody ) ||
			!Validator.isFunction( tableBody.cleanFromEditables ) ) {
			return false;
		}
		return tableBody.cleanFromEditables();
	}

	/**
	 * @override
	 * @see XCellItem.js~XCellItem#onCellContentClick
	 */
	onCellContentClick( domEvent ) {
		if ( !this.link ) {
			return this.onCellContainerClick( domEvent );
		}
		const editingAttempt = !Validator.isObject( domEvent ) ? false : !!domEvent.shiftKey || !!domEvent.transferredFromNeighbour;
		return editingAttempt ? this.onCellContainerClick( domEvent ) : this.onLinkClick( domEvent );
	}

	isHyperlinkClick( domEvent ) {
		if ( domEvent instanceof Event ) {
			return !this.link ? false : this.shouldBeSkipped ? true : !DomEventHelper.isShiftEvent( domEvent );
		}
		return false;
	}

	onCellContainerClick( domEvent ) {
		if ( this.droppedDown ) {
			// don't do anything but suppress the event!
			DomEventHelper.stopEvent(domEvent);
			this.focusCell(domEvent);
			return false;
		}
		if ( domEvent instanceof Event ) {
			const target = domEvent.target;
			if ( target instanceof HTMLElement ) {
				const input = this.inputField;
				if ( (input instanceof EditableElement) && (input.contentEditableElement === target) ) {
					// that's mouse click that was bubbling up
					return false;
				}
			}
		}
		this.clearPrevEditTarget();
		if ( this.isHyperlinkClick( domEvent ) && this.checkbox instanceof Checkbox ) {
			return this.onCheckboxHyperlink( domEvent );
		}
		if ( this.shouldBeSkipped ) {
			// TODO should it be done after the cell is focused?
			return false;
		}
		const eventComesFromAnotherCell = Validator.isObject( domEvent ) && !!domEvent.transferredFromNeighbour;
		const self = this;
		const xtwBody = this.xtwBody;
		xtwBody.ensureCellIsVisible(this, () => {
			self._finishCellContainerClick(domEvent, eventComesFromAnotherCell);
		});
	}

	/**
	 * finishes the "cell container click" handling
	 * @param {Event} domEvent the DOM event
	 * @param {Boolean} eventComesFromAnotherCell flag indicating whether the call comes from another cell
	 */
	_finishCellContainerClick(domEvent, eventComesFromAnotherCell) {
		this.trace('finishing cell container click');
		if ( !this.isFocused || eventComesFromAnotherCell ) {
			if ( domEvent instanceof Event ) {
				domEvent.transferredFromCellContainer = true;
			}
			this.focusCell( domEvent );
			this.setCellFocus();
		}
		DomEventHelper.stopEvent(domEvent);
		if ( this.isLogicDataType ) {
			if ( !this.thisCellIsCurrentlyEdited ) {
				this.cleanLastEditedCell();
				this.cleanFromEditables();
			}
			if ( this.checkbox instanceof Checkbox ) {
				this.checkbox.focusSelf();
			}
			return !eventComesFromAnotherCell ? true : (((this.checkbox instanceof Checkbox) && this.canBeEdited) ? this.checkbox.onInputClick( domEvent ) : true);
		}
		if ( this.isBlobDataType ) {
			return false;
		}
		const xr = this.row;
		if ( !(xr instanceof XRowItem) || !xr.modelItemIsValid ) {
			return false;
		}
		const mi = xr.item;
		if ( !mi.alive || mi.readOnlyDummy ) {
			return false;
		}
		return this.createAndFocusInputField( domEvent );
	}

	clearPrevEditTarget() {
		if ( this.thisCellIsCurrentlyEdited ) {
			return true;
		}
		const body = this.xtwBody;
		body.clearEditTarget(true);
	}

	focusCheckbox( domEvent ) {
		if ( !( this.checkbox instanceof Checkbox ) ) {
			return false;
		}
		if ( !this.thisCellIsCurrentlyEdited ) {
			this.cleanLastEditedCell();
			this.cleanFromEditables();
		}
		const eventComesFromAnotherCell = Validator.isObject( domEvent ) && !!domEvent.transferredFromNeighbour;
		if ( !this.isFocused || eventComesFromAnotherCell ) {
			if ( Validator.isObject( domEvent ) ) {
				domEvent.transferredFromCellContainer = true;
			}
			this.focusCell( domEvent );
		}
		return this.checkbox.focusSelf();
	}

	get thisCellIsCurrentlyEdited() {
		const body = this.xtwBody;
		if ( !Validator.isObject( body ) ) {
			return false;
		}
		if ( this.textarea instanceof Textarea &&
			body.textarea === this.textarea ) {
			return true;
		}
		if ( this.inputField instanceof InputField &&
			body.inputField === this.inputField ) {
			return true;
		}
		if ( this.checkbox instanceof Checkbox &&
			body.inputField === this.checkbox ) {
			return true;
		}
		return false;
	}

	onCellContainerKeyDown(domEvent) {
		if ( (domEvent instanceof KeyboardEvent) && !DomEventHelper.isProcessed(domEvent) && (this.checkbox instanceof Checkbox) ) {
			if ( DomEventHelper.keyIs(domEvent, ' ') ) {
				domEvent.stopImmediatePropagation();
				this.checkbox.onCheckboxSpace(domEvent);
			}
		}
	}

	onCellContainerKeyUp( domEvent ) {
		if ( DomEventHelper.keyIs( domEvent, "F2" ) ) {
			DomEventHelper.stopEvent(domEvent);
			return this.onCellContainerClick( domEvent );
		}
		if ( DomEventHelper.keyIs(domEvent, "Tab") ) {
			DomEventHelper.stopEvent(domEvent);
			this._setCurrentCellToEditMode();
		}
		return true;
	}

	onCheckboxHyperlink( domEvent ) {
		if ( domEvent instanceof Event ) {
			domEvent.stopPropagation();
			domEvent.preventDefault();
		}
		this.onLinkClick( domEvent );
		return true;
	}

	onCellContainerDoubleClick( domEvent ) {
		if ( !this.isBlobDataType ) {
			return false;
		}
		this._nfySrv( "blobDoubleClick" );
		return true;
	}

	createAndFocusInputField( domEvent ) {
		if ( this.isTraceEnabled() ) {
			this.trace(`>>> createAndFocusInputField( idc=${this.idc}, idr=${this.idr} )`);
		}
		if ( this.isLogicDataType ) {
			if ( this.inputField instanceof InputField ) {
				const input = this.inputField;
				this.inputField = null;
				input.destroySelfAndRestoreCell();
			}
			if ( !(this.checkbox instanceof Checkbox) ) {
				this.createCheckbox();
			}
			this.renderContent();
			this.checkbox.focusSelf();
		} else {
			if ( !( this.inputField instanceof InputField ) ) {
				this.cleanLastEditedCell();
				this.cleanFromEditables();
				this.canHaveDropdown ? new DropdownInputField( this ) : new InputField( this );
				this.inputField.render();
			}
			if ( !( this.inputField instanceof InputField ) ) {
				return false;
			}
			if ( window.document.activeElement !== this.inputField.input ) {
				if ( this.isDebugEnabled() ) {
					this.debug('Setting focus to the input element.');
				}
				this.inputField.input.focus( { preventScroll: true } );
			}
			if ( Validator.isFunction( this.inputField.addBlurListener ) ) {
				this.inputField.addBlurListener();
			}
			if ( Validator.isFunction( this.inputField.addMouseWheelListener ) ) {
				this.inputField.addMouseWheelListener();
			}
		}
		return true;
	}

	createAndFocusTextarea() {
		if ( !( this.textarea instanceof Textarea ) ) {
			new Textarea( this );
			this.textarea.render();
		}
		if ( !( this.textarea instanceof Textarea ) ) {
			return false;
		}
		if ( window.document.activeElement != this.textarea.textarea ) {
			this.textarea.textarea.focus( { preventScroll: true } );
		}
		return true;
	}

	destroyInputField() {
		if ( !( this.inputField instanceof InputField ) ) {
			return true;
		}
		if ( Validator.isFunction( this.inputField.destroySelfAndRestoreCell ) ) {
			return this.inputField.destroySelfAndRestoreCell();
		}
		if ( Validator.isFunction( this.inputField.destroySelf ) ) {
			return this.inputField.destroySelf();
		}
		return false;
	}

	destroyTextarea() {
		if ( !( this.textarea instanceof Textarea ) ) {
			return true;
		}
		if ( Validator.isFunction( this.textarea.destroySelfAndRestoreCell ) ) {
			return this.textarea.destroySelfAndRestoreCell();
		}
		if ( Validator.isFunction( this.textarea.destroySelf ) ) {
			return this.textarea.destroySelf();
		}
		return false;
	}

	resetDirty() {
		const editable = (this.inputField || this.textarea || this.checkbox);
		if ( editable instanceof EditableElement ) {
			editable.dirty = false;
		}
	}

	restoreContentSpan() {
		if ( this.isLogicDataType ) {
			if ( !( this.checkbox instanceof Checkbox ) ) {
				return false;
			}
			return this.checkbox.resetInput();
		}
		if ( !( this.alignElement instanceof HTMLElement ) ||
			!( this.cttElm instanceof HTMLElement ) ) {
			return false;
		}
		this.alignElement.appendChild( this.cttElm );
		return true;
	}

	/**
	 * retrieves the neighbor cell
	 * @param {Boolean} getNextCell direction flag
	 * @returns {XCellItem} the neighbor cell or null
	 */
	getNeighborEditableCell( getNextCell = true ) {
		if ( !Validator.isObjectPath( this.row, "row.cells" ) || !Validator.isFunction( this.row.cells.getItem ) ) {
			return void 0;
		}
		const orderedCellIds = this.row.orderedColumnIds;
		if ( !Validator.isArray( orderedCellIds ) ) {
			return void 0;
		}
		const currentCellOrderIndex = Validator.getIndexInArray( orderedCellIds, this.idc );
		if ( !Validator.isPositiveInteger( currentCellOrderIndex ) ) {
			return void 0;
		}
		const cells = this.row.cells;
		const directionProvider = !!getNextCell ? 1 : -1;
		let currentIndex = currentCellOrderIndex + directionProvider;
		let neCell = void 0;
		while ( currentIndex >= 0 && currentIndex < orderedCellIds.length ) {
			neCell = cells.getItem( orderedCellIds[ currentIndex ] );
			if ( Validator.isObject( neCell ) && !neCell.isBlob && !neCell.shouldBeSkipped ) {
				// we found the editable cell
				break;
			}
			currentIndex += directionProvider;
		}
		if ( Validator.isObject( neCell ) && !neCell.shouldBeSkipped && !neCell.isBlob ) {
			return neCell;
		}
		const neRow = !!getNextCell ? this.rowBelow : this.rowAbove;
		if ( !Validator.isObject( neRow ) ) {
			return void 0;
		}
		const editableCellInNeighborRow = !!getNextCell ? neRow.firstEditableVisibleInputCell : neRow.lastEditableVisibleInputCell;
		if ( !Validator.isObject( editableCellInNeighborRow ) ) {
			return void 0;
		}
		return !editableCellInNeighborRow.isBlob && !editableCellInNeighborRow.shouldBeSkipped ? editableCellInNeighborRow : editableCellInNeighborRow.getNeighborEditableCell( !!getNextCell );
	}

	get previousEditableCell() {
		return this.getNeighborEditableCell( false );
	}

	get nextEditableCell() {
		return this.getNeighborEditableCell( true );
	}

	getNeighborModelItem( getNextItem = true ) {
		const flatModel = this.flatModel;
		if ( !Validator.isArray( flatModel ) ) {
			return void 0;
		}
		const flatIndex = this._flatIndex;
		if ( !Validator.isPositiveInteger( flatIndex ) ) {
			return void 0;
		}
		const potentialNeighborModelItemIndex = !!getNextItem ? flatIndex + 1 : flatIndex - 1;
		if ( potentialNeighborModelItemIndex >= flatModel.length || potentialNeighborModelItemIndex < 0 ) {
			return void 0;
		}
		const neighborModelItem = flatModel[ potentialNeighborModelItemIndex ];
		return Validator.isObject( neighborModelItem ) ? neighborModelItem : void 0;
	}

	/**
	 * @returns {XCellItem} the previous neighbor cell or null
	 */
	get previousModelItem() {
		return this.getNeighborModelItem( false );
	}

	/**
	 * @returns {XCellItem} the next neighbor cell or null
	 */
	get nextModelItem() {
		return this.getNeighborModelItem( true );
	}

	getNeighborRow( getNextRow = true ) {
		const neighborModelItem = this.getNeighborModelItem( !!getNextRow );
		if ( !Validator.isObject( neighborModelItem ) ) {
			return void 0;
		}
		const neighborRow = this.xtwBody.getXRowItem(neighborModelItem);
		return neighborRow;
	}

	get rowAbove() {
		return this.getNeighborRow( false );
	}

	get rowBelow() {
		return this.getNeighborRow( true );
	}

	get cellBelow() {
		const nextRow = this.rowBelow;
		if ( !Validator.isObject( nextRow ) ||
			!Validator.is( nextRow.cells, "ObjReg" ) ) {
			// TODO if row is not rendered yet, scroll & render it
			return void 0;
		}
		const nextCell = nextRow.cells.getItem( this.idc );
		return Validator.isObject( nextCell ) ? nextCell : void 0;
	}

	get bodyClientRect() {
		const body = this.xtwBody;
		if ( !Validator.isObject( body ) || !( "clientRect" in body ) ) {
			return void 0;
		}
		return body.clientRect;
	}

	get fixedContainerClientRect() {
		const row = this.row;
		if ( !Validator.isObject( row ) || !( "fixedContainerClientRect" in row ) ) {
			return void 0;
		}
		return row.fixedContainerClientRect;
	}

	get isInTheVisibleRange() {
		return !this.isHiddenOnTheLeft && !this.isHiddenOnTheRight;
	}

	get isHiddenOnTheRight() {
		if ( this.isHiddenUnderFixedContainer ) {
			return true;
		}
		const cellRect = this.clientRect;
		if ( !( cellRect instanceof DOMRect ) ) {
			return false;
		}
		const bodyRect = this.bodyClientRect;
		if ( !( bodyRect instanceof DOMRect ) ) {
			return false;
		}
		return bodyRect.x + bodyRect.width < cellRect.x + cellRect.width;
	}

	get isHiddenOnTheLeft() {
		if ( this.isHiddenUnderFixedContainer ) {
			return true;
		}
		const cellRect = this.clientRect;
		if ( !( cellRect instanceof DOMRect ) ) {
			return false;
		}
		const bodyRect = this.bodyClientRect;
		if ( !( bodyRect instanceof DOMRect ) ) {
			return false;
		}
		return cellRect.x < bodyRect.x;
	}

	get isHiddenUnderFixedContainer() {
		if ( this.fix ) {
			return false;
		}
		const cellRect = this.clientRect;
		if ( !( cellRect instanceof DOMRect ) ) {
			return false;
		}
		const fixedContainerClientRect = this.fixedContainerClientRect;
		if ( !( fixedContainerClientRect instanceof DOMRect ) ) {
			return false;
		}
		return fixedContainerClientRect.x + fixedContainerClientRect.width >
			cellRect.x;
	}

	onInputTab( domEvent ) {
		if ( this.isTraceEnabled() ) {
			this.trace('onInputTab', domEvent);
		}
		const mi = this.row ? this.row.item : null;
		this.destroyInputField();
		this.destroyTextarea();
		this.restoreContentSpan();
		const isShiftEvent = DomEventHelper.isShiftEvent( domEvent );
		const nextCell = isShiftEvent ? this.previousEditableCell : this.nextEditableCell;
		DomEventHelper.stopEvent( domEvent );
		if ( Validator.isObject( domEvent ) ) {
			domEvent.transferredFromNeighbour = true;
		}
		const self = this;
		const xtwBody = this.xtwBody;
		if ( nextCell instanceof XCellItem ) {
			xtwBody.ensureCellIsVisible(nextCell, () => {
				return self._setCellFocus(nextCell);
			});
		} else if ( isShiftEvent && (mi instanceof MRowItem) && (mi.flatIndex > 0) ) {
			// we're a bit lost - there's no valid XRowItem yet, so we must scroll first and the try again
			xtwBody.ensureRowIsVisible(mi.flatIndex - 1, () => self._onInputTabTryAgain(isShiftEvent));
		}
		return true;
	}

	_onInputTabTryAgain(shift) {
		const nextCell = shift ? this.previousEditableCell : this.nextEditableCell;
		if ( nextCell instanceof XCellItem ) {
			const self = this;
			this.xtwBody.ensureCellIsVisible(nextCell, () => {
				return self._setCellFocus(nextCell);
			});
		}
	}

	_setCellFocus(cell) {
		if ( cell instanceof XCellItem ) {
			// return cell.focusCell(null);
			return cell._finishCellContainerClick(null, false);
		}
	}

	_setCurrentCellToEditMode() {
		// we need to figure out the real target cell
		const body = this.xtwBody;
		const model = this.model;
		const cell = body._getXCellItem(model.focusedColumn, model.focusedRow);
		if ( cell instanceof XCellItem ) {
			window.setTimeout(() => {
				if ( cell.alive ) {
					cell.enterEditingMode(null, null, false, null);
				}
			}, 0);
		}
	}

	onInputShiftTabInFirstCellInFirstRow( domEvent ) {
		// TODO THIS NEEDS REWORKING
		const row = this.row;
		if ( !Validator.isObject( row ) || !row.isFirstRenderedRow ||
			!Validator.isFunctionPath( row.tblBody, "tblBody.doAfterScrollingToModelItem" ) ) {
			return false;
		}
		const previousModelItem = this.previousModelItem;
		if ( !Validator.isObject( previousModelItem ) ) {
			return false;
		}
		DomEventHelper.stopIf( domEvent );
		if ( Validator.isObject( domEvent ) ) {
			domEvent.transferredFromNeighbour = true;
		}
		if ( !row.tblBody.scrollAndClickModelItem( previousModelItem ) ) {
			return false;
		}
		const callback = () => {
			if ( !Validator.isObjectPath( previousModelItem, "previousModelItem.row" ) ) {
				return false;
			}
			if ( !previousModelItem.row.isRendered ) {
				return false;
			}
			const lastEditableVisibleInputCell =
				previousModelItem.row.lastEditableVisibleInputCell;
			if ( !Validator.isObject( lastEditableVisibleInputCell ) ) {
				return false;
			}
			// TODO redundant code
			const scrolledToCell =
				lastEditableVisibleInputCell.ensureCellIsInTheVisibleRange();
			if ( !scrolledToCell ) {
				return lastEditableVisibleInputCell.enterEditingMode( domEvent, null, false, null );
			}
			const xtwBody = previousModelItem.xtwBody;
			if ( !Validator.isFunctionPath( xtwBody,
					"xtwBody.setupAfterScrollViewUpdateCallback" ) ) {
				return false;
			}
			xtwBody.setupAfterScrollViewUpdateCallback(
				"onInputShiftTabInFirstCellInFirstRow-", () => {
					if ( Validator.isFunction( xtwBody.forceSyncHorizontalScrollbar ) ) {
						xtwBody.forceSyncHorizontalScrollbar();
					}
					return lastEditableVisibleInputCell.enterEditingMode( domEvent, null, false, null );
				} );
			return true;
		};
		row.tblBody.setupModelDataCallback("onInputShiftTabInFirstCellInFirstRow-", callback );
		row.tblBody.setupEnsuingModelDataCallback("onInputShiftTabInFirstCellInFirstRow-", callback );
		return true;
	}

	/**
	 * enters the edit mode for this cell
	 * @param {Event} domEvent the DOM event, may be null
	 * @param {String} org optional original value 
	 * @param {Boolean} dropDown flag whether to immediately show the dropdown (if applicable)
	 * @returns 
	 */
	enterEditingMode( domEvent, org = null, dropDown = false, dirty = null ) {
		const ok = this.onCellContainerClick( domEvent );
		if ( ok ) {
			const inputField = this.inputField;
			if ( inputField instanceof EditableElement ) {
				if ( org !== null ) {
					inputField.setOvrOrgValue(org);
				}
				if ( Validator.isBoolean(dirty) ) {
					inputField.dirty = !!dirty;
				}
				if ( !!dropDown && this.canHaveDropdown ) {
					setTimeout( () => {
						if ( inputField.alive ) {
							inputField.openDropdown(null);
						}
					}, 0);
				}
			} else if ( !this._no_focus && (this.checkbox instanceof Checkbox) ) {
				// don't do anything here, i.e do NOT set the focus to the checkbox - it would destroy the layout completely
			}
		}
		return ok;
	}

	ensureCellIsInTheVisibleRange() {
		if ( this.isInTheVisibleRange ) {
			return false;
		}
		const xtwBody = this.xtwBody;
		if ( !Validator.isFunctionPath( xtwBody, "xtwBody.xtwHead.scrollToColumn" ) ) {
			return false;
		}
		return xtwBody.xtwHead.scrollToColumn( this.column );
	}

	onInputEnter( domEvent ) {
		if ( domEvent instanceof KeyboardEvent ) {
			domEvent.stopPropagation();
			domEvent.preventDefault();
		}
		const sameCellInTheRowUnder = this.cellBelow;
		if ( !Validator.isObject( sameCellInTheRowUnder ) ) {
			return false;
		}
		this.destroyInputField();
		this.destroyTextarea();
		this.restoreContentSpan();
		if ( Validator.isObject( domEvent ) ) {
			domEvent.transferredFromNeighbour = true;
		}
		if ( sameCellInTheRowUnder.checkbox instanceof Checkbox ) {
			this.focusCell( domEvent );
			return sameCellInTheRowUnder.focusCheckbox( domEvent );
		}
		return sameCellInTheRowUnder.onCellContainerClick( domEvent );
	}

	onInputEscape( domEvent ) {
		this.destroyInputField();
		this.destroyTextarea();
		this.restoreContentSpan();
		if ( domEvent instanceof KeyboardEvent ) {
			domEvent.stopPropagation();
			domEvent.preventDefault();
		}
		if ( !this.isFocused ) {
			this.focusCell( domEvent );
		}
		if ( this.isRendered ) {
			this.element.tabIndex = 1;
			this.element.focus( { preventScroll: true } );
		}
		return true;
	}

}
